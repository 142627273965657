import type { Ref } from 'vue'

export default function useSubNav(type: SubNavType | Ref<SubNavType | null>) {
  const { t } = useI18n()

  const navigationItems = computed<(NavigationItem | NavigationDivider)[]>(() => {
    const navType = unref(type)

    switch (navType) {
      case 'documents':
        return [
          {
            identifier: 'all',
            route: '/dashboard?tab=all',
            replace: true,
            title: t('home.all'),
            icon: 'custom:archive',
          },
          {
            identifier: 'uploaded',
            route: '/dashboard?tab=empty',
            replace: true,
            title: t('home.uploaded'),
            color: 'grey-dark',
          },
          {
            identifier: 'to_sign',
            route: '/dashboard?tab=toSign',
            replace: true,
            title: t('home.to_sign'),
            icon: 'custom:to_sign',
            color: 'warning',
          },
          {
            identifier: 'pending',
            route: '/dashboard?tab=pending',
            replace: true,
            title: t('home.pending'),
            icon: 'custom:currency_exchange',
            color: 'warning',
          },
          {
            identifier: 'declined',
            route: '/dashboard?tab=declined',
            replace: true,
            title: t('home.declined'),
            color: 'danger',
          },
          {
            identifier: 'completed',
            route: '/dashboard?tab=completed',
            replace: true,
            title: t('home.completed'),
            icon: 'custom:c_check',
            color: 'success',
          },
        ]
      case 'profile':
        return [
          {
            identifier: 'profile',
            route: '/profile',
            title: t('global.navigation.personal_data'),
            icon: 'custom:circle_profile',
          },
          {
            identifier: 'signature_standards',
            route: '/profile/signature-standards',
            title: t('global.navigation.signature_standards'),
            icon: 'custom:signature_standards',
          },
          {
            identifier: 'visual_signatures',
            route: '/profile/visual-signature',
            title: t('global.navigation.visual_signature'),
            icon: 'custom:artboard',
          },
          {
            identifier: 'referral',
            route: '',
            title: t('global.navigation.refer_a_friend'),
            icon: 'custom:referral',
          },
          {
            identifier: 'settings',
            route: '/profile/settings',
            title: t('global.navigation.settings'),
            icon: 'custom:gear',
          },
          { divider: true },
          { identifier: 'logout', route: '/logout', title: t('global.navigation.logout'), icon: 'custom:leave' },
        ]
      case 'business-profile':
        return [
          {
            identifier: 'profile',
            route: '/business/profile',
            title: t('business.navigation.business_profile'),
            icon: 'custom:business_profile',
          },
          {
            identifier: 'aes4biz',
            route: '/business/profile/aes-for-business',
            title: t('business.profile.navigation.aes'),
            icon: 'custom:business_aes',
          },
          { identifier: 'logout', route: '/logout', title: t('global.navigation.logout'), icon: 'custom:leave' },
        ]
      case 'business-members':
        return [
          {
            identifier: 'members',
            route: '/business/members',
            title: t('business.navigation.members'),
            icon: 'custom:multiple',
          },
          {
            identifier: 'domains',
            route: '/business/members/domains',
            title: t('business.members.navigation.domains'),
            icon: 'custom:at',
          },
        ]
      default:
        return []
    }
  })

  return {
    navigationItems,
  }
}
