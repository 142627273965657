<script lang="ts" setup>
const props = defineProps<{
  items: (NavigationItem | NavigationDivider)[]
}>()

const { xs } = useDisplay()

const id = useId()

const filteredItems = computed(() => props.items.filter((item): item is NavigationItem => !item.divider))
</script>

<template>
  <v-bottom-navigation v-if="items.length > 1" color="primary" grow :name="id">
    <v-btn v-for="(item, i) in filteredItems" :id="item.identifier" :key="`${item}-${i}`" :to="item.route" exact>
      <v-icon>{{ item.icon }}</v-icon>
      <span v-if="!xs">{{ item.title }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>
